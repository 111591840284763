import App from "@/App.vue";
import "@/plugins";
import router from "@/router";
import store from "@/store";
import "@/style/main.scss";
import Vue from "vue";
import VueSocialSharing from "vue-social-sharing";
import VueYouTubeEmbed from "vue-youtube-embed";
import i18n from "./i18n";

Vue.use(VueYouTubeEmbed);

Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
