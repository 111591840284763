import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing.vue"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("@/views/About.vue"),
  // },

  // {
  //   path: "/gallery",
  //   name: "Masonry",
  //   component: () => import("@/views/Masonry.vue"),
  // },
  // {
  //   path: "/magazine",
  //   name: "AllMagazines",
  //   component: () => import("@/views/AllMagazines.vue"),
  // },
  // {
  //   path: "/magazine/:slug",
  //   name: "Issue",
  //   component: () => import("@/views/AllMagazines.vue"),
  // },

  // {
  //   path: "/news/:slug",
  //   name: "News",
  //   component: () => import("@/views/News.vue"),
  // },
  // {
  //   path: "/stories/:slug",
  //   name: "Stories",
  //   component: () => import("@/views/LastStories.vue"),
  // },
  // {
  //   path: "/magazines/:slug",
  //   name: "Download",
  //   component: () => import("@/views/Download.vue"),
  // },
  // {
  //   path: "/:slug/:id/news",
  //   name: "Department",
  //   component: () => import("@/views/Department.vue"),
  // },
  // {
  //   path: "/stories",
  //   name: "Stories",
  //   component: () => import("@/views/Stories.vue"),
  // },
  // {
  //   path: "/programs",
  //   name: "Programmes",
  //   component: () => import("@/views/Programmes.vue"),
  // },
  // {
  //   path: "/programmes/:id",
  //   name: "Programme",
  //   component: () => import("@/views/Programm.vue"),
  // },

  // {
  //   path: "/share",
  //   name: "Share",
  //   component: () => import("@/views/Share.vue"),
  // },

  // // {
  // //   path: "/theGalary",
  // //   name: "TheGalary",
  // //   component: () => import("@/views/TheGalary.vue"),
  // // },
  // {
  //   path: "/news",
  //   name: "Last News",
  //   component: () => import("@/views/LastNews.vue"),
  // },
  {
    path: "*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
